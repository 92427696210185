<template>
    <div>
        <div class="lg:w-3/5 w-full h-full border-r flex">
            <Transition enter-from-class="opacity-0 -translate-x-full"
                                    enter-to-class="opacity-100 translate-x-0"
                                    enter-active-class="transform transition ease-out duration-150 delay-200"
                                    leave-active-class="transform transition ease-in duration-150"
                                    leave-from-class="opacity-100 translate-x-0"
                                    leave-to-class="opacity-0 -translate-x-full" appear>
                <SearchFilter v-if="innerWidth > 768 || showFilter" @closeFilter="showFilter = false" @queriesUpdated="refreshResults" v-model:sortBy="sortBy" />
            </Transition>

            <div class="lg:w-2/3 w-full h-full bg-white">
                <SimpleBar class="h-full p-5">
                    <div v-if="listings" class="space-y-5 lg:pb-0 pb-28">
                        <a v-for="listing in listings.data" :href="listing.url" target="_blank" class="border block rounded-lg overflow-hidden hover:shadow-lg hover:cursor-auto lg:flex lg:h-44 relative hover:cursor-pointer">
                            <div class="lg:w-2/5 w-full relative">
                                <div v-if="(dayjs().diff(listing.created_at, 'day') < 5) && listing.status.name == 'available'" class="bg-primary text-white p-1 rounded text-xs shadow-xl top-2 left-2 absolute inline-block">New Listing</div>
                                <div v-if="listing.status.name != 'available'" class="p-1 rounded text-xs shadow-xl text-white absolute inline-block top-2 left-2" :style="`background-color:${ listing.status.color }`">{{ $t(`statuses.${ listing.status.name }`) }}</div>

                                <img v-if="listing.photos.length > 0" :src="listing.photos[0].image_url" class="w-full h-full object-cover" />
                            </div>
                            <div class="lg:w-3/5 w-full p-5 lg:space-y-1 space-y-2">
                                <h2 class="text-gray-800 font-semibold">{{ listing.title }}</h2>
                                <div v-if="listing.address" class="text-sm">
                                    {{ listing.address.district }} {{ listing.address.city }}
                                </div>

                                <div class="font-semibold">
                                    {{ listing.price.display_price }}
                                </div>

                                <ul class="text-sm flex space-x-8">
                                    <li class="text-center" v-if="listing.features.areas.living_area> 0">
                                        <i class="fi fi-rr-draw-square block"></i>
                                        <span>{{ listing.features.areas.living_area }} m²</span>
                                    </li>
                                    <li class="text-center" v-if="listing.features.areas.plot_size > 0">
                                        <i class="fi fi-rr-border-all block"></i>
                                        <span>{{ listing.features.areas.plot_size }} m²</span>
                                    </li>
                                    <li class="text-center" v-if="listing.features.layout.numbers_of_bedrooms > 0">
                                        <i class="fi fi-rr-bed-alt block"></i>
                                        <span>{{ listing.features.layout.numbers_of_bedrooms }}</span>
                                    </li>
                                </ul>
                            </div>

                            <toggle-listing-favourite v-if="user" ref="toggleListingFavourite" :listing_id="listing.id" class="absolute top-3 right-3">
                                <template v-slot:is-not-saved>
                                    <button class="text-gray-400 text-2xl hover:text-red-500"><i class="fi fi-rr-heart"></i></button>
                                </template>

                                <template v-slot:is-saved>
                                    <button class="text-2xl text-red-500"><i class="fi fi-rr-heart"></i></button>
                                </template>
                            </toggle-listing-favourite>

                        </a>
                    </div>

                    <div v-if="loading" class="space-y-5">
                        <div v-for="x in 10" class="block space-y-2 animate-pulse lg:flex">
                            <div class="lg:w-2/5 w-full rounded-lg overflow-hidden relative bg-gray-300 h-56">
                            </div>

                            <div class="lg:w-3/5 w-full lg:px-5 space-y-3">
                                <div class="bg-gray-300 h-6 rounded w-full"></div>

                                <div class="bg-gray-300 h-6 rounded w-2/3"></div>

                                <div class="bg-gray-300 h-4 rounded w-1/2"></div>

                                <div class="space-x-5 flex">
                                    <div class="w-9 h-9 bg-gray-300 rounded"></div>
                                    <div class="w-9 h-9 bg-gray-300 rounded"></div>
                                    <div class="w-9 h-9 bg-gray-300 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="lg:hidden flex justify-center">
                        <div class="fixed bottom-20 text-white bg-black font-bold rounded-full shadow-lg flex divide-x">
                            <button class="flex items-center space-x-3 py-2 px-3" @click="showFilter = true">
                                <i class="fi fi-rr-settings-sliders flex items-center"></i>
                                <span>Filter</span>
                            </button>
                            <button class="flex items-center space-x-3 py-2 px-3" @click="showMap = true">
                                <i class="fi fi-rr-map-marker flex items-center"></i>
                                <span>Map</span>
                            </button>
                        </div>
                    </div>

                    <div ref="loadMore"></div>
                </SimpleBar>
            </div>
        </div>

        <Transition enter-from-class="opacity-0 translate-x-full"
                                    enter-to-class="opacity-100 translate-x-0"
                                    enter-active-class="transform transition ease-out duration-150 delay-200"
                                    leave-active-class="transform transition ease-in duration-150"
                                    leave-from-class="opacity-100 translate-x-0"
                                    leave-to-class="opacity-0 translate-x-full" appear>
            <div v-if="innerWidth > 768 || showMap" class="lg:w-2/5 w-full h-full lg:static" v-bind:class="{'fixed z-30': showMap, 'z-[5]': !showMap}">
                <ListingsMap class="w-full h-full z-20 top-0 left-0" uri="listing/map-markers" ref="listingsMap" />
                <div class="lg:hidden flex justify-center">
                    <div class="fixed bottom-5 text-white bg-black font-bold rounded-full shadow-lg flex divide-x z-30">
                        <button class="flex items-center space-x-3 py-2 px-3" @click="showMap = false">
                            <i class="fi fi-rr-cross-small flex items-center"></i>
                            <span>Close Map</span>
                        </button>
                    </div>
                </div>
            </div>
        </Transition>

    </div>
</template>


<script>
    import { ref } from 'vue'

    import { useApi } from '../lib/api'
    import { useUser } from '../lib/user'
    import { useSearch } from '../lib/search'
    import { useDebounceFn } from '@vueuse/core'

    import ToggleListingFavourite from './ToggleListingFavourite.vue'
    import ListingsMap from './ListingsMap.vue'
    import SearchFilter from './SearchFilter.vue'
    import SimpleBar from './SimpleBar.vue'

    import dayjs from 'dayjs'

    export default {
        props: ['queries'],
        components: {
            ToggleListingFavourite,
            ListingsMap,
            SearchFilter,
            SimpleBar
        },
        setup(props, { emit }){
            const { setSearchQuery, getUrlQuery } = useSearch()
            const { get, loading, data } = useApi('listings')
            const page = ref(1);
            const loadable = ref(true)
            const listings = ref(null)
            const showFilter = ref(false)
            const showMap = ref(false)
            const listingsMap = ref(null)
            const sortBy = ref(props.queries.sort_by ?? 'relevance')

            let controller = new AbortController()

            const innerWidth = ref(0)

            const { user } = useUser()

            setSearchQuery(props.queries)

            const getListings = () => {
                if(loadable.value) {
                    const queries = Object.fromEntries(Object.entries(getUrlQuery()).filter(([_, v]) => v != ''))

                    get({
                        ...queries,
                        ...{
                            page: page.value,
                            sort_by: sortBy.value
                        }
                    },{
                        signal: controller.signal
                    }).then(() => {
                        if(data.value.status) {
                            loadable.value = data.value.listings.current_page !== data.value.listings.last_page

                            if(listings.value) {
                                listings.value.data = listings.value.data.concat(data.value.listings.data)

                                return
                            }

                            listings.value = data.value.listings
                        }
                    })
                }
            }

            getListings()

            const refreshResults = useDebounceFn(() => {
                controller.abort()
                controller = new AbortController()

                page.value = 1
                listings.value = null
                loadable.value = true

                getListings()

                if(listingsMap.value) {
                    listingsMap.value.getMarkers()
                }
            }, 1000)

            return {
                listings,
                user,
                showFilter,
                showMap,
                innerWidth,
                loading,
                listingsMap,
                getListings,
                refreshResults,
                page,
                dayjs,
                sortBy
            }
        },
        mounted() {
            var vm = this

            window.onresize = function(event) {
                vm.innerWidth = (event.srcElement || event.currentTarget).innerWidth
            }

            this.innerWidth = window.innerWidth

            let observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if(entry.intersectionRatio > 0 && !this.loading && this.listings) {

                        this.page = this.page + 1
                        this.getListings()
                    }
                })
            })

            observer.observe(this.$refs.loadMore)
        }
    }
</script>
